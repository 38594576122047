import { FederatedPointerEvent } from "pixi.js";
import { RectangleTransformer } from "../objects/transformers/RectangleTransformer";
import { Engine, EngineState } from "../engine";
import {
  BasePatchMenuConfig,
  CanvasMenuConfig,
} from "@/nexus/pages/quilt-editor/canvas/canvas-context-menu-configs";
import { ScrollingStateManager } from "./interactions/ScrollingStateManager";
import { ObjectMovingEngine } from "./interactions/ObjectMovingEngine";
import { BaseObject } from "../objects/BaseObject";
import { RectangleObject } from "../objects/RectangleObject";

/**
 * Handles the logic around interacting with the user and the canvas.
 *
 * Rename this InteractionManager
 */
export class InteractionController {
  engine: Engine;
  #rectangleTransformer: RectangleTransformer;
  #scrollingStateManager: ScrollingStateManager;
  #objectMovingEngine: ObjectMovingEngine;

  constructor(engine: Engine) {
    this.engine = engine;
    this.#rectangleTransformer = new RectangleTransformer(this.engine);
    this.#scrollingStateManager = new ScrollingStateManager(this.engine);
    this.#objectMovingEngine = new ObjectMovingEngine(this.engine);
  }

  onPointerDownBackground(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.ObjectSelected) {
      // Go back to browsing.
      this.engine.setEngineState(EngineState.Browsing);
    }

    if (this.engine.currentState == EngineState.ContextMenuOpen) {
      // Close the context menu.
      this.engine.browserConnector.setCanvasContextMenuState(event.screenX, event.globalY, false);
      this.engine.setEngineState(EngineState.Browsing);
    }
  }

  // TODO: Have different Drawing Types.
  onPointerDownStage(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.Drawing) {
      if (this.engine.app && this.engine.cursorStateManager && this.engine.canvasStateManager) {
        // This PointerDown with the other ones... handleBasePatchClickEvent?
        const pendingBasePatch = this.#rectangleTransformer.startCreation(event);
        // We want to disable interactivity for the other items.
        if (pendingBasePatch) {
          this.engine.canvasStateManager.setNonInteractive();
          this.engine.canvasStateManager.setContainer(pendingBasePatch);
          this.engine.app.stage.addChild(pendingBasePatch.container);
        }
      }
      return;
    }

    if (this.engine.currentState == EngineState.Browsing && event.button == 2) {
      this.engine.browserConnector.setCanvasContextMenuState(
        event.screenX,
        event.globalY,
        true,
        new CanvasMenuConfig()
      );
      this.engine.currentState = EngineState.ContextMenuOpen;
      return;
    }

    if (
      this.engine.currentState == EngineState.ScrollingPending ||
      this.engine.currentState == EngineState.ScrollingActive
    ) {
      this.#scrollingStateManager.onPointerDown(event);
      return;
    }
  }

  onPointerMoveStage(engineState: EngineState, event: FederatedPointerEvent): void {
    if (engineState == EngineState.Drawing) {
      this.#rectangleTransformer.onPointerMove(event);
      return;
    }

    if (engineState == EngineState.ScrollingActive) {
      this.#scrollingStateManager.onPointerMove(event);
      return;
    }

    if (engineState == EngineState.MovingObjectActive) {
      this.#objectMovingEngine.onPointerMove(event);
      return;
    }

    if (engineState == EngineState.TransformingObjectActive) {
      this.#rectangleTransformer.onPointerMove(event);
      return;
    }
  }

  onPointerUpStage(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.Drawing) {
      if (
        this.engine.cursorStateManager &&
        this.engine.canvasStateManager &&
        this.engine.engineStateManager
      ) {
        this.#rectangleTransformer.onPointerUp(event);
        this.engine.canvasStateManager.setNonInteractive();
        this.engine.engineStateManager.save();
      }
      return;
    }

    if (
      this.engine.currentState == EngineState.ScrollingPending ||
      this.engine.currentState == EngineState.ScrollingActive
    ) {
      this.#scrollingStateManager.onPointerUp(event);
      return;
    }

    if (this.engine.currentState == EngineState.MovingObjectActive) {
      this.#objectMovingEngine.onPointerUp();
      return;
    }

    if (this.engine.currentState == EngineState.TransformingObjectActive) {
      this.#rectangleTransformer.onPointerUp(event);
      this.engine.engineStateManager?.save();
      this.engine.setEngineState(EngineState.ObjectSelected);
      return;
    }
  }

  onPointerDownInteractor(
    baseObject: BaseObject,
    event: FederatedPointerEvent,
    interactorType: string,
    interactorId: number
  ): void {
    // TODO: Add a ObjectType and InteractorType.
    // TODO: Check Type of Object
    if (this.engine.currentState == EngineState.ObjectSelected && event?.button == 0) {
      this.#rectangleTransformer.startTransform(
        baseObject as RectangleObject,
        interactorType,
        interactorId
      );
      this.engine.setEngineState(EngineState.TransformingObjectActive);
    }
  }

  onPointerDownBaseObject(baseObject: BaseObject, event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.Browsing) {
      // https://github.com/pixijs/pixijs/issues/5910
      // Double click will open the editor.
      // this.engine.browserConnector.openEditor();
      if (event?.button == 0) {
        console.log(baseObject);
        // TODO: this is smelly.
        this.#objectMovingEngine.currentObject = this.engine.canvasStateManager?.getBasePatch(
          baseObject.config.id
        );
        this.engine.canvasStateManager?.setSingleActivePatch(baseObject.config.id);
        this.#objectMovingEngine.onPointerDown(event);
        this.engine.canvasStateManager?.setCurrentObject(baseObject);
        this.engine.browserConnector.setObjectConfig(baseObject.getConfig());
        console.log("What");
        return;
      } else {
        this.engine.browserConnector.setCanvasContextMenuState(
          event.screenX,
          event.globalY,
          true,
          new BasePatchMenuConfig(baseObject.config.id)
        );
        this.engine.currentState = EngineState.ContextMenuOpen;
      }
      return;
    }

    // TODO: Let's rename these into functions taht we can human read.
    // This means we selected a different patch, so now we select a different one.
    if (this.engine.currentState == EngineState.ObjectSelected) {
      if (event?.button == 0) {
        // TODO: this is smelly.
        this.#objectMovingEngine.currentObject = this.engine.canvasStateManager?.getBasePatch(
          baseObject.config.id
        );
        this.engine.canvasStateManager?.setSingleActivePatch(baseObject.config.id);
        this.#objectMovingEngine.onPointerDown(event);
        this.engine.canvasStateManager?.setCurrentObject(baseObject);
        this.engine.browserConnector.setObjectConfig(baseObject.getConfig());
      }
      return;
    }

    if (this.engine.currentState == EngineState.ContextMenuOpen) {
      if (event?.button == 0) {
        console.log("two?");
        this.engine.browserConnector.setCanvasContextMenuState(event.screenX, event.globalY, false);
        this.engine.setEngineState(EngineState.Browsing);
      } else {
        this.engine.browserConnector.setCanvasContextMenuState(
          event.screenX,
          event.globalY,
          true,
          new BasePatchMenuConfig(baseObject.config.id)
        );
        this.engine.setEngineState(EngineState.ContextMenuOpen);
      }
      return;
    }
  }
}
