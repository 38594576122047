import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Engine } from "@/nexus/engine/engine";
import { ObjectConfig } from "@/nexus/engine/types/WorkingSpaceTypes";
import { useState } from "react";
import { DataConfigurator } from "./canvas-editor/data-configurator";
import { ObjectUpdateKeys } from "@/nexus/engine/types/ObjectTypes";

export interface CanvasEditorProps {
  engine: Engine;
}

// TODO: Put this within the type, and perhaps... get rid of the any?
type ObjectInput = string | number;

// TODO: This entire state will change, depending on the mode.
//  - If we have something selected, the selection window will open up.
//  - Have some basic configuration for now.
//  - The editing flow might be very different in the future.
const CanvasEditor = ({ engine }: CanvasEditorProps): JSX.Element => {
  // Editing flow:
  //  - Select a container
  //  - Change a field
  //  - Have that field save on that container
  //  - Have that field

  // TODO: We should have this in sync with engine.currentConfig.
  const [objectConfig, setObjectConfig] = useState<ObjectConfig | undefined>(undefined);
  engine.browserConnector.initializeCanvasEditor(setObjectConfig);
  // TODO: This might want to be in a useEffect.
  // TODO: Should we just have state for every single thing?
  const titleChange = (name: string) => {
    if (objectConfig) {
      engine.updateObject({ title: name });
      const newObject = { ...objectConfig };
      newObject.name = name;
      setObjectConfig(newObject);
    }
  };

  const keyChange = (key: string, value: ObjectInput) => {
    if (objectConfig && key in ObjectUpdateKeys) {
      engine.updateObject({ [key]: value });
      const newObject = { [key]: value, ...objectConfig };
      setObjectConfig(newObject);
    }
  };

  // Reset objectConfig.

  // Data Configuration.
  //  - Data Source (dropdown, eventually dynamic).
  //  - Display type (right now... just a number).
  //  -

  // We use the key prop in TabsContent to force render everything
  // when we change ObjectConfigs. This way we can have local state within
  // each component that doesn't care about underlying changes for the engine.
  //
  // TODO: We should pass through the objectConfig.

  const canvasConfigContent = (
    <Tabs defaultValue="object">
      <TabsList>
        <TabsTrigger value="object">Canvas</TabsTrigger>
        <TabsTrigger value="editor">Editor</TabsTrigger>
      </TabsList>
      <TabsContent value="object"></TabsContent>
      <TabsContent value="editor"></TabsContent>
    </Tabs>
  );

  // TODO: We can probably have the "height" be a key type
  // or something.
  let objectConfigContent = <div></div>;
  if (objectConfig) {
    objectConfigContent = (
      <div>
        <Tabs defaultValue="object" key={objectConfig.id}>
          <TabsList>
            <TabsTrigger value="object">Essentials</TabsTrigger>
            <TabsTrigger value="design">Design</TabsTrigger>
          </TabsList>
          <TabsContent value="object">
            <Card>
              <CardHeader>
                <CardTitle>object</CardTitle>
                <CardDescription>Make changes to your object here.</CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                {objectConfig.name != undefined ? (
                  <Input
                    type="string"
                    onChange={(event) => {
                      titleChange(event.target.value);
                    }}
                    placeholder="Title"
                    value={objectConfig.name}
                  />
                ) : null}
              </CardContent>
            </Card>
            <DataConfigurator
              engine={engine}
              currentObjectId={objectConfig?.id}
              objectConfig={objectConfig}
            />
          </TabsContent>

          <TabsContent value="design">
            <Card>
              <CardHeader>
                <CardTitle>Design</CardTitle>
                <CardDescription>Make changes to your object here.</CardDescription>
              </CardHeader>
              <CardContent className="space-y-2 flex">
                <span className="p-4 text-xs">X</span>
                <Input
                  type="number"
                  onChange={(event) => {
                    keyChange("x", event.target.value);
                  }}
                  placeholder=""
                  value={objectConfig.x}
                />
              </CardContent>
              <CardContent className="space-y-2 flex">
                <span className="p-4 text-xs">Y</span>
                <Input
                  type="number"
                  onChange={(event) => {
                    keyChange("y", event.target.value);
                  }}
                  placeholder=""
                  value={objectConfig.y}
                />
              </CardContent>
              <CardContent className="space-y-2 flex">
                <span className="p-4 text-xs">Height</span>
                <Input
                  type="number"
                  onChange={(event) => {
                    keyChange("height", event.target.value);
                  }}
                  placeholder=""
                  value={objectConfig.height}
                />
              </CardContent>
              <CardContent className="space-y-2 flex">
                <span className="p-4 text-xs">Width</span>
                <Input
                  type="number"
                  onChange={(event) => {
                    keyChange("width", event.target.value);
                  }}
                  placeholder=""
                  value={objectConfig.width}
                />
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </div>
    );
  }

  return objectConfig ? objectConfigContent : canvasConfigContent;
};

export default CanvasEditor;
