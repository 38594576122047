import { Button } from "@/components/ui/button";
import { Engine } from "@/nexus/engine/engine";
import { useState } from "react";
import {
  BasePatchMenuConfig,
  CanvasContextMenuState,
  CanvasMenuConfig,
} from "./canvas-context-menu-configs";

export interface CanvasContextMenuProps {
  engine: Engine;
}
const CanvasContextMenu = ({ engine }: CanvasContextMenuProps): JSX.Element => {
  const [canvasContextMenuState, setCanvasContextMenuState] = useState<CanvasContextMenuState>({
    x: 0,
    y: 0,
    show: false,
  });
  engine.browserConnector.initializeCanvasContextMenu(setCanvasContextMenuState);

  let menu = <div />;
  const menuConfig = canvasContextMenuState.menuConfig;
  if (menuConfig instanceof BasePatchMenuConfig) {
    const basePatchMenuConfig = menuConfig as BasePatchMenuConfig;
    const click = () => {
      const possibleId = basePatchMenuConfig.id;
      if (possibleId && engine.canvasStateManager) {
        // Delete the id and then reset this context menu.
        engine.canvasStateManager.deleteItem(possibleId);
        setCanvasContextMenuState({
          x: 0,
          y: 0,
          show: false,
        });
      }
    };
    menu = (
      <div>
        <Button onClick={() => click()}> Delete Item </Button>
      </div>
    );
  } else if (menuConfig instanceof CanvasMenuConfig) {
    // Do something.
    menu = (
      <div>
        <Button onClick={() => engine.clearEngine()}>Clear</Button>{" "}
      </div>
    );
  }

  return (
    <div
      hidden={!canvasContextMenuState.show}
      style={{
        zIndex: 2,
        top: canvasContextMenuState.y,
        left: canvasContextMenuState.x,
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      {menu}
    </div>
  );
};

export default CanvasContextMenu;
