import { z } from "zod";

import { DataConfigObject, DataThresholdObject } from "./WorkingSpaceTypes";
/**
 * ObjectUpdate are all of the possible fields that you could send in to update an object.
 *
 * They're all Optional and it's up to the object itself to handle it.
 */

const DataUpdateObject = z.object({
  dataConfig: DataConfigObject.optional(),
  dataThreshold: DataThresholdObject.optional(),
});
export type DataUpdate = z.infer<typeof DataUpdateObject>;

const ObjectUpdate = z.object({
  title: z.string().optional(),
  x: z.number().optional(),
  y: z.number().optional(),
  width: z.number().optional(),
  height: z.number().optional(),
  primaryNumber: z.number().optional(),
  color: z.string().optional(),
  dataIntegration: DataUpdateObject.optional(),
});
export const ObjectUpdateKeys = ObjectUpdate.keyof();
export type ObjectUpdate = z.infer<typeof ObjectUpdate>;
