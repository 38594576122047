import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import React, { useState } from "react";
import { trpc } from "../..//utils/trpc";
import { Toaster } from "@/components/ui/toaster";
import { Canvas } from "../../pages/quilt-editor/canvas";
import { ThemeProvider } from "@/components/theme-provider";

function DashboardPage(): JSX.Element {
  // TODO: Hook up the autohrization into the trpcClient.
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: "http://localhost:4000/trpc/",
          // This is for CORS.
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    })
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
          <div>
            <Toaster />
            <Canvas />
          </div>
        </ThemeProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default DashboardPage;
