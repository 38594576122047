/**
 * DynamicEngine is responsible for dispatching the state updates for dynamic
 * changes in objects due to changes in the data.
 *
 *
 * Simplest case: let's change a number that tracks to an external metric.
 *
 * - DynamicEngine will get that number update change (from a wss connection or mocked).
 * - Then we'll figure out _which_ component this corresponds to.
 * - Make the appropriate change to that component.
 *
 * - When we get a new update in the queue, we can make the appropriate changes in the UI.
 *    - There a sync version (call processQueue()) or an async process (have an internal
 *      ticker that pops items off of the queue and then runs those updates).
 *
 * - We likely want a _maximum_ amount of items that we process in a tick.
 *   - In the future - we likely only want to update those within the current canvas.
 *
 *
 */

import { Engine } from "../../engine";
import { BaseObject } from "../../objects/BaseObject";

export interface DynamicStateUpdate {
  // BasePatch id...
  // TODO: this might not know the id (on the server...) ->
  //   We need to keep a map here of all of the metrics -> [base_patch ids]
  //   We can build this map based on all of the BasePatch information, and changes
  //   when we update that in the configuration window.
  // Metric information, that triggers an update.
  // Container_id -> because some things will not be visible.
  //   These are within the basePatch configuration (serde) but within JS are children.

  metric_id: string;
  metric_value: number;
}

// We need to know the configurations -> to then trigger an update.
// BasePatch contains all of the configurations.

export class DataUpdateController {
  engine: Engine;
  stateUpdates: DynamicStateUpdate[] = [];

  // We keep a map of metric_id to the Object.
  stateMap: Map<string, BaseObject[]> = new Map();

  constructor(engine: Engine) {
    this.engine = engine;

    // TODO: This is a mocked server, should push this out to the server and
    // setup that tRPC flow into the Canvas.
    setInterval(() => {
      this.stateUpdates.push({
        metric_id: "mocked",
        metric_value: 100 - Math.floor(Math.random() * 100),
      });
    }, 1000);

    // TODO: Think of a better way to do this.
    setInterval(() => {
      this.runUpdates();
    }, 1000);
  }

  // BackendConnector pushes updates here.
  addUpdates(updates: DynamicStateUpdate[]): void {
    this.stateUpdates.concat(updates);
  }

  /**
   * Run updates - this may impact many (or all, or none) of the units that are currently
   * being rendered on the screen for the user.
   */
  runUpdates(): void {
    // Probably have to do this in some threadsafe manner or something later down the line.
    const currentUpdates = this.stateUpdates;
    this.stateUpdates = [];

    // Look through the basePatchMap, and look at the update.
    // trigger an update.
    currentUpdates.forEach((update) => {
      const baseObjects = this.stateMap.get(update.metric_id);
      if (baseObjects) {
        baseObjects.forEach((object) => {
          object.updateObject({
            primaryNumber: update.metric_value,
          });
        });
      }
    });
  }

  /**
   * Run this every time we change the possible data configurations.
   */
  updateStateMap(baseObjectMap: Map<string, BaseObject>): void {
    // NOTE: In the future this could just be based on a change, don't have
    // to redo this every time.
    this.stateMap.clear();

    baseObjectMap.forEach((object) => {
      const dataSourceId = object.config.dataIntegration?.dataConfig.dataSource;
      if (dataSourceId && dataSourceId != "") {
        const entry: BaseObject[] = this.stateMap.get(dataSourceId) || [];
        // NOTE: Wonder if we should do this object passing around vs. updating via key...
        entry.push(object);
        this.stateMap.set(dataSourceId, entry);
      }
    });
  }
}
