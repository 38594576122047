/**
 * Handles connecting the Canvas to the Backend (Zerus !!!)
 */

import { WorkingSpace } from "../types/WorkingSpaceTypes";

const workingSpaceKey = "workingSpaceKey_[1.0.8]";

// TODO: Using https://typia.io/docs/setup/ for JSON parsing with types.
// This _might_ be come unneeded with tRPC, going to think about how we want to do this.

// This class should be managing data -> this is essentially the RPC stub. This can just be localStorage for now.
export class BackendConnector {
  fetchWorkingSpace(): WorkingSpace {
    const fetchedItems = localStorage.getItem(workingSpaceKey);
    if (fetchedItems) {
      // TODO: verify via zod.
      return JSON.parse(fetchedItems);
    }

    // Default case, return nothing.
    return [];
  }

  deleteWorkingSpace(): void {
    localStorage.removeItem(workingSpaceKey);
  }

  deleteCanvas(workingSpace: WorkingSpace, index: number): void {
    workingSpace.splice(index, 1);
    const workingSpaceString = JSON.stringify(workingSpace);
    localStorage.setItem(workingSpaceKey, workingSpaceString);
  }

  // NOTE: For now - let's just do the simple version (storing the entire state everytime).
  // There is a version where we just send change logs back and forth to the server - events that would have changed
  // the underlying state, which are then handled and saved.
  // Every action has an appropriate [change event] -> that changes the state and is broadcast appropriately.
  //
  // Or there is a version where we pass through a single canvas to be saved.
  saveCanvas(workingSpace: WorkingSpace): void {
    const workingSpaceString = JSON.stringify(workingSpace);
    localStorage.setItem(workingSpaceKey, workingSpaceString);
  }
}
