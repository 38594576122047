import { BaseObject } from "./BaseObject";
import { Engine } from "../engine";
import { ObjectConfig } from "../types/WorkingSpaceTypes";
import { RectangleObject } from "./RectangleObject";

export function objectFromConfig(engine: Engine, config: ObjectConfig): BaseObject {
  const basePatch = new RectangleObject(engine, config);
  basePatch.finishDrawingObject();
  return basePatch;
}
