import { Container, FederatedPointerEvent, Graphics } from "pixi.js";
import { BaseObject, HOVER_COLOR } from "./BaseObject";
import { BACKGROUND_CANVAS_COLOR, Engine } from "../engine";
import { ObjectUpdate } from "../types/ObjectTypes";
import { ObjectConfig } from "../types/WorkingSpaceTypes";

// Corner Constants
const CORNER_SIZE = 10;
const CORNER_OFFSET = 6;
const CORNER_THICKNESS = 1;

export const TOP_LEFT = 0;
export const TOP_RIGHT = 1;
export const BOTTOM_LEFT = 2;
export const BOTTOM_RIGHT = 3;

// Sides Constants
export const SIDE_THICKNESS = 1;

export const TOP = 0;
export const BOTTOM = 1;
export const LEFT = 2;
export const RIGHT = 3;

// NOTE: Instead of drawing all of the Rectangles for every base object,
// We could just add them when the Rectangle is clicked, and destroyed
// when this object is no longer active.
//
// We could also have a global one - that we move around and make visible
// when an object is marked active...
//
// If this ever causes a slowdown, or we want to do some performance improvements
// this is a good optimization.
//
//
// TODO: https://pixijs.com/8.x/guides/advanced/render-groups
export class RectangleObject extends BaseObject {
  // Have the four corners and the four sides as objects tied with
  // this one.
  #corners: Graphics[];
  #sidesInteractions: Container[];

  constructor(engine: Engine, config?: ObjectConfig) {
    super(engine, config);

    this.#corners = [];
    this.#sidesInteractions = [];
  }

  finishDrawingObject(): void {
    super.finishDrawingObject();
    this.createInteractors();
  }

  updateObject(update: ObjectUpdate): void {
    super.updateObject(update);
    this.calculateInteractorsPositions();
  }

  setActive(): void {
    super.setActive();
    this.turnOn();
  }

  setInactive(): void {
    super.setInactive();
    this.turnOff();
  }

  turnOn(): void {
    this.#corners.forEach((corner) => {
      corner.visible = true;
    });
    this.#sidesInteractions.forEach((side) => {
      side.visible = true;
    });
  }

  turnOff(): void {
    this.#corners.forEach((corner) => {
      corner.visible = false;
    });
    this.#sidesInteractions.forEach((side) => {
      side.visible = false;
    });
  }

  createInteractors(): void {
    for (let i = 0; i < 4; i++) {
      const sideInteraction = new Graphics()
        .rect(0, 0, SIDE_THICKNESS * 4, SIDE_THICKNESS * 4)
        // For debugging, swap out for the light green.
        // .fill("5cc463");
        .fill({ alpha: 0 });
      this.#sidesInteractions.push(sideInteraction);
      this.container.addChild(sideInteraction);
    }

    // Create the corners next.
    for (let i = 0; i < 4; i++) {
      const corner = new Graphics()
        .rect(0, 0, CORNER_SIZE, CORNER_SIZE)
        .fill(BACKGROUND_CANVAS_COLOR)
        .stroke({ width: CORNER_THICKNESS, color: HOVER_COLOR });
      corner.visible = false;
      this.container.addChild(corner);
      this.#corners.push(corner);
    }

    this.#corners[TOP_LEFT].cursor = "nwse-resize";
    this.#corners[TOP_RIGHT].cursor = "nesw-resize";
    this.#corners[BOTTOM_LEFT].cursor = "nesw-resize";
    this.#corners[BOTTOM_RIGHT].cursor = "nwse-resize";
    this.#sidesInteractions[TOP].cursor = "ns-resize";
    this.#sidesInteractions[RIGHT].cursor = "ew-resize";
    this.#sidesInteractions[BOTTOM].cursor = "ns-resize";
    this.#sidesInteractions[LEFT].cursor = "ew-resize";

    this.calculateInteractorsPositions();

    this.#sidesInteractions.forEach((side, index) => {
      side.eventMode = "static";
      side.on("pointerdown", async (event: FederatedPointerEvent) => {
        this.engine.cursorStateManager?.onPointerDownInteractor(this, event, "side", index);
      });
    });

    // This is where the ObjectTransformEngine comes in, and where we pass through the index.
    this.#corners.forEach((corner, index) => {
      // Add the click handlers, that enter "resizing mode".
      corner.eventMode = "static";
      corner.on("pointerdown", async (event: FederatedPointerEvent) => {
        this.engine.cursorStateManager?.onPointerDownInteractor(this, event, "corner", index);
      });
    });
  }

  calculateInteractorsPositions(): void {
    // For each, put at the corners - remember: x, y refer to the top left corner of both.
    if (this.#corners.length > 0) {
      this.#corners[TOP_LEFT].x = 0 - CORNER_SIZE + CORNER_OFFSET;
      this.#corners[TOP_LEFT].y = 0 - CORNER_SIZE + CORNER_OFFSET;
      this.#corners[TOP_RIGHT].x = this.config.width - CORNER_OFFSET;
      this.#corners[TOP_RIGHT].y = 0 - CORNER_SIZE + CORNER_OFFSET;
      this.#corners[BOTTOM_LEFT].x = 0 - CORNER_SIZE + CORNER_OFFSET;
      this.#corners[BOTTOM_LEFT].y = this.config.height - CORNER_OFFSET;
      this.#corners[BOTTOM_RIGHT].x = this.config.width - CORNER_OFFSET;
      this.#corners[BOTTOM_RIGHT].y = this.config.height - CORNER_OFFSET;
    }

    if (this.#sidesInteractions.length > 0) {
      this.#sidesInteractions[TOP].width = this.config.width;
      this.#sidesInteractions[TOP].y = 0 - SIDE_THICKNESS * 2;

      this.#sidesInteractions[RIGHT].height = this.config.height;
      this.#sidesInteractions[RIGHT].x = this.config.width - SIDE_THICKNESS * 2;

      this.#sidesInteractions[BOTTOM].width = this.config.width;
      this.#sidesInteractions[BOTTOM].y = this.config.height - SIDE_THICKNESS * 2;

      this.#sidesInteractions[LEFT].x = 0 - SIDE_THICKNESS * 2;
      this.#sidesInteractions[LEFT].height = this.config.height;
    }
  }

  destroyInteractors(): void {
    this.#corners.forEach((object) => {
      object.destroy();
    });
    this.#sidesInteractions.forEach((object) => {
      object.destroy();
    });
  }
}
