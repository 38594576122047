import { Engine } from "../engine";
import { BaseObject } from "../objects/BaseObject";
import { objectFromConfig } from "../objects/ObjectFactory";
import { ObjectConfig } from "../types/WorkingSpaceTypes";
import { ObjectUpdate } from "../types/ObjectTypes";
import { DataUpdateController } from "./canvas-states/DataUpdateController";

/**
 * Handles the working state of the Canvas.
 *
 * This is the CURRENT canvas.
 */
export class CanvasStateManager {
  // This is the actual Typescript objects that run the Canvas.
  baseObjectMap: Map<string, BaseObject> = new Map();

  // Need a map of all of the containers that are live.

  engine: Engine;

  // TODO: Should move to ObjectAttributeController
  // This cnotrol pattern is super gross and hard to reason about.
  currentBasePatch: BaseObject | undefined;

  dataUpdateController: DataUpdateController;

  constructor(engine: Engine) {
    this.engine = engine;
    this.dataUpdateController = new DataUpdateController(engine);
  }

  init(): void {
    this.dataUpdateController.updateStateMap(this.baseObjectMap);
  }

  /**
   * Object configuration flow:
   *
   * 1. The UI builds out an ObjectUpdate, given a state of the ObjectConfig.
   * 2. That ObjectUpdate is sent through, which updates the Object's config.
   * 3. That Object config is then copied and sent through to the UI.
   */
  updateObject(objectUpdate: ObjectUpdate): boolean {
    // NOTE: Not exactly sure that this is working as we think :) re: object key validation.
    this.currentBasePatch?.updateObject(objectUpdate);
    // TODO: This is wasteful?
    this.dataUpdateController.updateStateMap(this.baseObjectMap);
    // TODO: Should this live in engine?
    if (this.currentBasePatch) {
      this.engine.browserConnector.setObjectConfig(this.currentBasePatch.config);
    }
    return true;
  }

  getObjectConfig(): ObjectConfig | undefined {
    if (this.currentBasePatch) {
      return this.currentBasePatch.config;
    }
    return undefined;
  }

  resetCanvas(): void {
    this.currentBasePatch?.refreshObject();
    this.currentBasePatch = undefined;
    this.engine.browserConnector.resetObjectConfig();
  }

  setCurrentObject(basePatch: BaseObject): void {
    this.currentBasePatch = basePatch;
  }

  getBasePatch(id: string): BaseObject | undefined {
    return this.baseObjectMap.get(id);
  }

  setBasePatch(patch: BaseObject): void {
    this.baseObjectMap.set(patch.config.id, patch);
  }

  setSingleActivePatch(id: string): void {
    this.baseObjectMap.forEach((patch: BaseObject) => {
      if (patch.config.id == id) {
        patch.setActive();
      } else {
        patch.setInactive();
      }
    });
  }

  // This is the current Screen offset (that has been committed, e.g. when a scrolling action stops).
  // We need to know this when setting the canvasX and canvasY in the basePatch.
  offsetX = 0;
  offsetY = 0;

  // Should this be in... this?
  // This doesn't seem that much better lol
  movePatch(patch: BaseObject, offsetX: number, offsetY: number): void {
    if (patch.isActive) {
      // Update with the current offset.
      patch.container.x = patch.config.x + this.offsetX + offsetX;
      patch.container.y = patch.config.y + this.offsetY + offsetY;
    }
  }

  updateOffset(offsetX: number, offsetY: number): void {
    this.offsetX += offsetX;
    this.offsetY += offsetY;
  }

  liveUpdateOffset(offsetX: number, offsetY: number): void {
    // Update each base patch container.
    this.baseObjectMap.forEach((basePatch: BaseObject) => {
      basePatch.container.x = basePatch.config.x + this.offsetX + offsetX;
      basePatch.container.y = basePatch.config.y + this.offsetY + offsetY;
    });
  }

  updateToFreshCanvas(): void {
    // If we want to update the canvas from state, let's start with a blank slate.
    this.clearCanvas();

    // Check the state, and update accordingly.
    this.engine.engineStateManager
      ?.currentCanvas()
      .basePatches.forEach((basePatchConfig: ObjectConfig) => {
        if (this.engine.app && this.engine.cursorStateManager) {
          const newPatch = objectFromConfig(this.engine, basePatchConfig);
          // Wonder if this should be in finishDrawing() call.
          this.engine.canvasStateManager?.setContainer(newPatch);
          this.engine.app.stage.addChild(newPatch.container);
        }
      });
  }

  // https://pixijs.com/8.x/guides/components/graphics
  // Memory Leaks: Call destroy() on any Graphics object you no longer need to avoid memory leaks.
  clearCanvas(): void {
    this.baseObjectMap.forEach((basePatch: BaseObject, _key: string) => {
      basePatch.container.destroy();
    });

    this.baseObjectMap = new Map();
  }

  deleteItem(id: string): void {
    this.baseObjectMap.get(id)?.container.destroy();
    this.baseObjectMap.delete(id);

    // TODO: This is getting out of controooool (the different interfaces calling each other)
    //   ... what if we made all of these private, and forced the control flow to be through engine always.
    this.engine.engineStateManager?.save();
  }

  setContainer(basePatch: BaseObject): void {
    this.baseObjectMap.set(basePatch.config.id, basePatch);
  }

  setNonInteractive(): void {
    // Disable interaction
    this.baseObjectMap.forEach((basePatch: BaseObject) => {
      basePatch.container.interactiveChildren = false;
    });
  }

  unselectAll(): void {
    // Enable interaction
    this.baseObjectMap.forEach((basePatch: BaseObject) => {
      basePatch.setInactive();
    });
  }

  setInteractive(): void {
    // Enable interaction
    this.baseObjectMap.forEach((basePatch: BaseObject) => {
      basePatch.container.interactiveChildren = true;
    });
  }
}
