import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Engine } from "@/nexus/engine/engine";
import { DataConfig, DataIntegration, ObjectConfig } from "@/nexus/engine/types/WorkingSpaceTypes";
import { Input } from "@/components/ui/input";
import { DataUpdate } from "@/nexus/engine/types/ObjectTypes";

const dataSources = [
  {
    value: "",
    label: "No Data",
  },
  {
    value: "mocked",
    label: "Mocked Data",
  },
];

export interface DataSelectorProps {
  engine: Engine;
  currentObjectId: string;
  objectConfig: ObjectConfig;
}

// This is in DataEditor, it should completely update.
export function DataConfigurator({
  engine,
  currentObjectId,
  objectConfig,
}: DataSelectorProps): React.ReactElement {
  const [open, setOpen] = React.useState(false);

  const integrationName = objectConfig.dataIntegration?.dataConfig.dataSource || "";
  const currentThreshold = objectConfig.dataIntegration?.thresholdConfig?.threshold || 0;

  const onSelect = (currentValue: string) => {
    const dataConfig: DataConfig = { dataSource: currentValue, dataKey: "1" };
    const dataIntegration: DataIntegration = { dataConfig };
    engine?.updateObject({ dataIntegration: dataIntegration });
    setOpen(false);
  };

  const thresholdChange = (threshold: number) => {
    const dataIntegration: DataUpdate = { dataThreshold: { threshold } };
    engine?.updateObject({ dataIntegration: dataIntegration });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>data</CardTitle>
        <CardDescription>Add live data for {currentObjectId}!</CardDescription>
      </CardHeader>
      <CardContent className="space-y-2">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="w-[200px] justify-between"
            >
              {integrationName
                ? dataSources.find((data) => data.value === integrationName)?.label
                : "Select data source..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0">
            <Command>
              <CommandInput placeholder="Select data..." />
              <CommandEmpty>No framework found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {dataSources.map((data) => (
                    <CommandItem key={data.value} value={data.value} onSelect={onSelect}>
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          integrationName === data.value ? "opacity-100" : "opacity-0"
                        )}
                      />
                      {data.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </CardContent>
      {integrationName ? (
        <CardContent className="space-y-2 flex">
          <span className="p-4 text-xs">Threshold</span>
          <Input
            type="number"
            onChange={(event) => {
              thresholdChange(Number(event.target.value));
            }}
            placeholder=""
            value={currentThreshold}
          />
        </CardContent>
      ) : null}
    </Card>
  );
}
