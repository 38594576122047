export interface CanvasContextMenuState {
  // Display Properties
  x: number;
  y: number;
  show: boolean;

  // Possible CanvasContextStates
  menuConfig?: CanvasContextMenuConfig;
}

export interface CanvasContextMenuConfig {}

export class BasePatchMenuConfig implements CanvasContextMenuConfig {
  id: string;

  constructor(id: string) {
    this.id = id;
  }
}

export class CanvasMenuConfig implements CanvasContextMenuConfig {}
