import { Application, FederatedPointerEvent, Graphics } from "pixi.js";
import { CanvasStateManager } from "./state-managers/CanvasStateManager";
import { InteractionController } from "./state-managers/InteractionController";
import { BrowserConnector } from "./connectors/BrowserConnector";
import { EngineStateManager } from "./state-managers/EngineStateManager";
import { BackendConnector } from "./connectors/BackendConnector";
import { ObjectUpdate } from "./types/ObjectTypes";
import { ObjectConfig } from "./types/WorkingSpaceTypes";

// EngineState machine, there really is only one "action" that's live at a time
// from a given user. This is really CursorState, no?
export enum EngineState {
  Browsing = "Browsing",
  Drawing = "Drawing",
  ScrollingPending = "ScrollingPending",
  ScrollingActive = "ScrollingActive",
  ContextMenuOpen = "ContextMenuOpen",
  // This is when you're moving one or more objects.
  ObjectSelected = "ObjectSelected",
  MovingObjectActive = "MovingObjectActive",
  TransformingObjectActive = "TransformingObjectActive",
}
export const DEFAULT_ENGINE_STATE: EngineState = EngineState.Browsing;
export const BACKGROUND_CANVAS_COLOR = "e6e6e6";

/**
 * Engine is the central component that handles managing the Quilts flow.
 */
export class Engine {
  // Passed in via constructor.
  backendConnector: BackendConnector;
  browserConnector: BrowserConnector;

  // After initialization
  app: Application | undefined;
  backgroundGraphic: Graphics | undefined;
  activeGraphic: Graphics | undefined; // TODO: used for drag and drop and such.

  // TODO: Managers, it's possible we make this private to force a better interface.
  canvasStateManager: CanvasStateManager | undefined;
  cursorStateManager: InteractionController | undefined;
  engineStateManager: EngineStateManager | undefined;

  // Top Level Engine State
  // TODO: On a better way to manage this.
  currentState: EngineState = EngineState.Browsing;
  constructor(backendConnector: BackendConnector, browserConnector: BrowserConnector) {
    this.browserConnector = browserConnector;
    this.backendConnector = backendConnector;
  }

  // This object is initialized async.
  init(app: Application): void {
    // Initialize all of the async values.
    // TODO: This passing around of values is a lil smelly - in that they all need to reference each other lol.
    // This is messy.
    this.app = app;
    this.canvasStateManager = new CanvasStateManager(this);
    this.cursorStateManager = new InteractionController(this);
    this.engineStateManager = new EngineStateManager(this);

    // TODO: Figure out a better way to have the background size dynamically change.
    this.backgroundGraphic = new Graphics().rect(0, 0, 2000, 2000).fill("#e6e6e6");
    this.backgroundGraphic.eventMode = "static";
    app.stage.addChild(this.backgroundGraphic);

    app.stage.eventMode = "static";
    // NOTE - this bugs out: app.stage.hitArea = app.screen;

    // Initialize the storage for the squares.
    this.engineStateManager.init();
    this.canvasStateManager.init();

    // Handle Pointer Down Events
    // Base Patches will also have pointerdown click events.
    app.stage.on("pointerdown", async (event: FederatedPointerEvent) => {
      this.cursorStateManager?.onPointerDownStage(event);
    });

    app.stage.on("pointermove", async (event) => {
      this.cursorStateManager?.onPointerMoveStage(this.currentState, event);
    });

    app.stage.on("pointerup", async (event) => {
      this.cursorStateManager?.onPointerUpStage(event);
    });

    // There is some overlap going on.
    app.stage.on("pointerupoutside", async (event) => {
      this.cursorStateManager?.onPointerUpStage(event);
    });

    // Clicking on the background is different.
    this.backgroundGraphic.on("pointerdown", async (event: FederatedPointerEvent) => {
      this.cursorStateManager?.onPointerDownBackground(event);
    });
  }

  setEngineState(engineState: string): void {
    console.log("Changing engineState = " + engineState);
    switch (engineState) {
      case EngineState.Browsing:
        if (this.backgroundGraphic && this.app) {
          this.backgroundGraphic.cursor = "default";
        }
        this.currentState = engineState;
        this.canvasStateManager?.unselectAll();
        this.canvasStateManager?.resetCanvas();
        return this.canvasStateManager?.setInteractive();
      case EngineState.Drawing:
        if (this.backgroundGraphic) {
          this.backgroundGraphic.cursor = "crosshair";
        }
        this.currentState = engineState;
        return this.canvasStateManager?.setNonInteractive();
      case EngineState.ScrollingPending:
        if (this.backgroundGraphic && this.app) {
          this.backgroundGraphic.cursor = "grab";
        }

        this.currentState = engineState;
        return this.canvasStateManager?.setNonInteractive();
      case EngineState.ScrollingActive:
        if (this.backgroundGraphic) {
          this.backgroundGraphic.cursor = "grabbing";
        }
        this.currentState = engineState;
        return this.canvasStateManager?.setNonInteractive();
      case EngineState.ObjectSelected:
        if (this.app) {
          this.app.stage.zIndex = 0;
        }
        this.currentState = engineState;
        return;
      case EngineState.MovingObjectActive:
        if (this.backgroundGraphic) {
          this.backgroundGraphic.cursor = "grabbing";
        }
        this.currentState = engineState;
        if (this.app) {
          this.app.stage.zIndex = 100;
        }
        return;
      case EngineState.TransformingObjectActive:
        this.currentState = engineState;
        if (this.app) {
          this.app.stage.zIndex = 100;
        }
        return;
    }
  }

  // TODO: Change this to QuiltConfig, so that we can drive many changes.
  setTitle(title: string): void {
    // Update the underlying storage.
    if (this.engineStateManager) {
      this.engineStateManager.currentCanvas().name = title;
    }

    // TODO: This is likely pretty inefficient, we should either jitter this or have this
    // set title be done on the last change.
    // Update the workspace?
    this.browserConnector.refreshWorkspaceTable();
    this.engineStateManager?.save();
  }

  updateObject(objectUpdate: ObjectUpdate): void {
    this.canvasStateManager?.updateObject(objectUpdate);
    this.engineStateManager?.save();
  }

  getObjectConfig(): ObjectConfig | undefined {
    if (this.canvasStateManager) {
      return this.canvasStateManager.getObjectConfig();
    }
    return undefined;
  }

  clearEngine(): void {
    this.canvasStateManager?.clearCanvas();
    this.engineStateManager?.save();

    // Close the context menu (if it was open as this can only be triggered there.)
    this.browserConnector.setCanvasContextMenuState(0, 0, false);
  }
}
