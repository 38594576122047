import { Container, Graphics, FederatedPointerEvent, Text, TextStyle } from "pixi.js";
import { ObjectConfig } from "../types/WorkingSpaceTypes";
import { Engine } from "../engine";
import { ObjectUpdate } from "../types/ObjectTypes";

const ROUND_RADIUS: number = 8;

export const HOVER_COLOR: number = 0x4f98ff;

// TODO: Need to think about zIndex and layers.
// e.g. 100 per layer, 101 for the hover container (as it's in the back), 102 for the container, 103 for the background.
const BASE_PATCH_Z_INDEX = 10;

// TODO: Object interface?
export class BaseObject {
  // These are used to render and interact with the quilt.
  container: Container;
  engine: Engine;
  #background: Graphics;

  // If this is selectable and movable, e.g. if we're dragging it.
  isActive = false;
  hover = false;

  // These are default children for all Objects.
  nameText: Text;
  numberText: Text;
  numberValue: number;

  // Configuration of the object.
  config: ObjectConfig;

  // Clean this up - move color, id, name out.
  constructor(engine: Engine, config?: ObjectConfig) {
    this.engine = engine;

    // Initialize the pixi.js Container.
    this.container = new Container();
    this.container.zIndex = BASE_PATCH_Z_INDEX;
    this.container.cursor = "pointer";

    // TODO: Why isn't this complaining...
    this.config = config || {
      id: getRandomId(),
      x: 0,
      y: 0,
      height: 0,
      width: 0,
      color: "#b8b8b8",
      name: getRandomId(),
    };

    this.container.x = this.config.x;
    this.container.y = this.config.y;
    // TODO:
    // There is an issue where the Inter-* files from the below
    // don't get served via assets correctly.
    //
    // https://github.com/pixijs/pixijs/issues/9412
    // https://rsms.me/inter/
    const style = new TextStyle({
      fontFamily: "Inter",
      fontSize: 12,
      fontWeight: "bold",
    });

    this.#background = new Graphics()
      .roundRect(0, 0, this.config.width, this.config.height, ROUND_RADIUS)
      .fill(this.config.color);
    this.container.addChild(this.#background);

    this.nameText = new Text({ text: this.config.name, style });
    this.nameText.x = 4;
    this.nameText.y = 4;
    // Default - this is invisible.
    this.nameText.visible = true;
    this.container.addChild(this.nameText);

    const numberStyle = new TextStyle({
      fontFamily: "Inter",
      fontSize: 64,
      fontWeight: "bolder",
    });
    this.numberText = new Text({ text: "99%", style: numberStyle });
    this.numberValue = 99;
    this.numberText.visible = false;
    this.container.addChild(this.numberText);
    // x, y is relative to _this_ container.
    // TODO: Have this be automatically centered.
    // Default - this is invisible.
    this.container.eventMode = "static";

    // Register the pointer events. Wonder if we could do this at init().
    // TODO: Not entirely sure this should be patched through like this.
    // Also not sure if this container vs. background make sense.
    this.container.on("pointerdown", async (event: FederatedPointerEvent) => {
      this.hover = false;
      this.engine.cursorStateManager?.onPointerDownBaseObject(this, event);
      this.refreshObject();
    });

    // TODO: pointerover capture? If we don't want things to propogate down to the children,
    // think about it when creating children.
    this.container.on("pointerover", async (_event: FederatedPointerEvent) => {
      // TODO: Clean this up, this is weird
      if (this.isActive && this.engine.backgroundGraphic) {
        this.container.cursor = "grab";
      }

      this.onHover();
    });

    this.container.on("pointerleave", async (_event: FederatedPointerEvent) => {
      if (!this.isActive) {
        this.onHoverExit();
      }
    });
  }

  updateObject(update: ObjectUpdate): void {
    if (update.title) {
      this.config.name = update.title;
      this.nameText.text = update.title;
    }

    if (update.x) {
      this.config.x = update.x - (this.engine.canvasStateManager?.offsetX || 0);
      this.container.x = update.x;
    }

    if (update.y) {
      this.config.y = update.y - (this.engine.canvasStateManager?.offsetY || 0);
      this.container.y = update.y;
    }

    if (update.width) {
      this.config.width = update.width;
    }

    if (update.height) {
      this.config.height = update.height;
    }

    if (update.color) {
      this.config.color = update.color;
    }

    if (this.config.dataIntegration && update.primaryNumber) {
      // TODO: Check to see if this update actually should be handled by this object.
      this.numberText.text = update.primaryNumber + "%";
      this.numberValue = update.primaryNumber;
    }

    if (update.dataIntegration) {
      // Update the dataConfig first, as it's required.
      if (update.dataIntegration.dataConfig) {
        // TODO: Unsure if this newDataConfig is needed to make a copy and reduce side effects.
        const newDataConfig = { ...update.dataIntegration.dataConfig };
        if (this.config.dataIntegration) {
          this.config.dataIntegration.dataConfig = newDataConfig;
        } else {
          this.config.dataIntegration = { dataConfig: newDataConfig };
        }
      }

      if (update.dataIntegration.dataThreshold) {
        if (this.config.dataIntegration) {
          this.config.dataIntegration.thresholdConfig = update.dataIntegration.dataThreshold;
        } else {
          console.log("Error: threshold update sent without a data integration.");
        }
      }
    }

    this.refreshObject();
  }

  refreshObject(): void {
    if (this.config.dataIntegration && this.config.dataIntegration.dataConfig) {
      if (this.config.dataIntegration.dataConfig.dataSource == "mocked") {
        this.numberText.visible = true;
      }
    }
    // anchor is where the container is set around, default is top left corner, 0.5 is middle.
    //   - since we wqant this centered, we anchor in the middle and then set in the middle.
    //
    // TODO: We should have a single codepath that calculates everything and then does the update.
    if (this.numberText) {
      this.numberText.anchor.set(0.5);
      this.numberText.x = this.config.width / 2;
      this.numberText.y = this.config.height / 2;
    }

    if (this.nameText) {
      this.nameText.anchor.set(0.5);
      this.nameText.x = this.config.width / 2;
      this.nameText.y = 24;
    }

    this.#background.clear();
    this.#background
      .roundRect(0, 0, this.config.width, this.config.height, ROUND_RADIUS)
      .stroke(this.__getStroke())
      .fill(this.__getColor());
  }

  __getColor(): string {
    let currentColor = this.config.color;
    if (this.config.dataIntegration) {
      if (this.config.dataIntegration.thresholdConfig) {
        if (this.numberValue > this.config.dataIntegration.thresholdConfig.threshold) {
          // TODO: Wire up the color.
          currentColor = "076b00";
        } else {
          currentColor = "940101";
        }
      }
    }
    return currentColor;
  }

  __getStroke(): Object {
    return this.hover || this.isActive
      ? {
          width: 2,
          color: HOVER_COLOR,
        }
      : {};
  }

  // This is called when we finish drawing the object.
  finishDrawingObject(): void {
    // NOTE: If this is called before the drawing is finished, than the drawing function gets weird.
    this.config.x = this.container.x - (this.engine.canvasStateManager?.offsetX || 0);
    this.config.y = this.container.y - (this.engine.canvasStateManager?.offsetY || 0);

    this.config.width = this.container.width;
    this.config.height = this.container.height;

    this.refreshObject();
  }

  onHover(): void {
    this.hover = true;
    this.refreshObject();
  }
  onHoverExit(): void {
    this.hover = false;
    this.refreshObject();
  }

  // TODO: rename.
  enablePrimaryNumber(): void {}

  // There should probably just be a serde method here, instead of passing configurations back and forth.
  getConfig(): ObjectConfig {
    return this.config;
  }

  setActive(): void {
    this.isActive = true;
  }

  setInactive(): void {
    this.isActive = false;
    this.hover = false;
    this.#background.cursor = "pointer";
  }

  calculateBackgroundColor(): string {
    return this.config.color;
  }

  debug(): void {
    console.log(
      "Debug log for BasePatch id = " +
        this.config.id +
        ", canvasX=" +
        this.config.x +
        ", canvasY=" +
        this.config.y +
        ", containerX=" +
        this.container.x +
        ", containerY=" +
        this.container.y
    );
  }
}

// This hsould become a UUID at some point.
function getRandomId(): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
