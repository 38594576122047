import { FederatedPointerEvent } from "pixi.js";
import { Engine, EngineState } from "../../engine";

/**
 * Handles all logic when we're scrolling.
 *
 *
 * TODO: This is less of a STATE manager, but more of a action class.
 */
export class ScrollingStateManager {
  engine: Engine;

  startX = 0;
  startY = 0;

  lastOffsetX = 0;
  lastOffsetY = 0;

  constructor(engine: Engine) {
    this.engine = engine;
  }

  onPointerDown(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.ScrollingPending) {
      // We're starting to scroll - save the current coordinates to calculate the offset.
      this.startX = event.globalX;
      this.startY = event.globalY;

      // Turn on scrolling.
      this.engine.setEngineState(EngineState.ScrollingActive);
    } else if (this.engine.currentState == EngineState.ScrollingActive) {
      // Don't think this should be a valid state, we should log this.
    }
  }

  onPointerMove(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.ScrollingActive) {
      // Calculate offset, and update.

      // The X, Y refers to the top left coordinate that the container starts at.
      // We only need to modify this (not the width or height of each container).
      //
      // Start here, we need to
      // 1. Say we go to the left, from 100 to 0 on the x axis: 0  <-  30 <-   100
      // 2. We want the items in the canvas to go to the left 100 units.
      //    - Imagine grabbing an object and pulling it.
      // 3. So, we should update each of the units X by -100.
      //    - In the middle (e.g. at 30) we would have traveled 70 units to the left, (100 -> 30).
      // 4. So, we hsould update by the current position minus the start position.
      //    - We are now at 0, 0 - 100 = -100

      // But now... we need to make sure that this is additive.
      // So say we've scrolled 10 ->
      this.lastOffsetX = event.globalX - this.startX;
      this.lastOffsetY = event.globalY - this.startY;

      this.engine.canvasStateManager?.liveUpdateOffset(this.lastOffsetX, this.lastOffsetY);
    }
  }

  onPointerUp(_event: FederatedPointerEvent): void {
    // Commit canvas offset.
    this.engine.canvasStateManager?.updateOffset(this.lastOffsetX, this.lastOffsetY);

    // Reset.
    this.lastOffsetX = 0;
    this.lastOffsetY = 0;
    this.engine.setEngineState(EngineState.ScrollingPending);
  }
}
