import * as React from "react";
import { QueryClient } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { useAuth } from "@clerk/clerk-react";
import { Outlet, useNavigate } from "react-router-dom";
import { useState } from "react";
import { trpc } from "../utils/trpc";

export default function DashboardLayout(): React.ReactElement {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  const [_queryClient] = useState(() => new QueryClient());
  const [_trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: "http://localhost:4000/trpc/",
          // This is for CORS.
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
        }),
      ],
    })
  );

  React.useEffect(() => {
    if (isLoaded && !userId) {
      navigate("/sign-in");
    }
  }, [isLoaded]);

  if (!isLoaded) return <div>"Loading..."</div>;

  return <Outlet />;
}
