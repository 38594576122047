import { FederatedPointerEvent } from "pixi.js";
import { Engine } from "../../engine";
import {
  BOTTOM,
  BOTTOM_LEFT,
  BOTTOM_RIGHT,
  LEFT,
  RIGHT,
  RectangleObject,
  TOP,
  TOP_LEFT,
  TOP_RIGHT,
} from "../RectangleObject";

/**
 * Handles all logic for transforming Rectangles (drawing, changing shape, etc).
 *
 * We could have a global set of corners...
 */
export class RectangleTransformer {
  engine: Engine;

  constructor(engine: Engine) {
    this.engine = engine;
  }

  // TODO: On corner, side -> send in the corner constants.
  //  -> Then we can have the different logics here
  //  ->
  currentRectangle: RectangleObject | undefined;

  isDrawing = false;
  startX: number = 0;
  startY: number = 0;
  currentColor: number = 0;

  // For interactor based transforms.
  interactorType: string = ""; // Either 0 for 1 de
  interactorId: number | undefined;
  startHeight: number = 0;
  startWidth: number = 0;

  /**
   * This is to kick off a creation of this object.
   *
   * @param event
   * @returns
   */
  startCreation(event: FederatedPointerEvent): RectangleObject | undefined {
    if (!this.isDrawing && this.engine.app && this.engine.cursorStateManager) {
      this.isDrawing = true;
      this.startX = event.globalX;
      this.startY = event.globalY;
      this.currentRectangle = new RectangleObject(this.engine);
      this.currentRectangle.updateObject({ x: this.startX, y: this.startY, height: 0, width: 0 });

      return this.currentRectangle;
    }

    return undefined;
  }

  /**
   * This is to kick off a transform, e.g. expanding the sides or pulling the corners.
   *
   * @param rectangle
   * @param interactorType
   */
  startTransform(rectangle: RectangleObject, interactorType: string, interactorId: number): void {
    this.currentRectangle = rectangle;
    this.interactorType = interactorType;
    this.interactorId = interactorId;
    this.startY = rectangle.container.y;
    this.startX = rectangle.container.x;
    this.startHeight = rectangle.config.height;
    this.startWidth = rectangle.config.width;
  }

  /**
   * This is called when moving in the context of a transform or creation.
   *
   * Maybe we should split it out? onPointerMoveTransform, etc.
   *
   * @param event
   * @returns
   */
  onPointerMove(event: FederatedPointerEvent): void {
    if (this.currentRectangle && this.isDrawing) {
      this.updatePendingRectangle(event);
      return;
    }

    if (this.interactorType == "side") {
      this.onPointerMoveForSide(event);
    }

    if (this.interactorType == "corner") {
      this.onPointerMoveForCorner(event);
    }
  }

  onPointerUp(event: FederatedPointerEvent): void {
    if (this.isDrawing && this.currentRectangle) {
      this.updatePendingRectangle(event);
      this.currentRectangle.finishDrawingObject();

      this.isDrawing = false;
      this.currentRectangle = undefined;
    }

    if (this.currentRectangle && this.interactorId != undefined) {
      this.currentRectangle = undefined;
      this.interactorId = undefined;
      this.startY = 0;
    }
  }

  updatePendingRectangle(event: FederatedPointerEvent): void {
    const currentX = event.globalX;
    const currentY = event.globalY;

    this.currentRectangle?.updateObject({
      x: Math.min(this.startX, currentX),
      y: Math.min(this.startY, currentY),
      width: Math.abs(currentX - this.startX),
      height: Math.abs(currentY - this.startY),
    });
  }

  /**
   * This is to handle the corner cases for resizing a Rectangle.
   *
   * TODO: We could add here a minimum size, e.g. 10 pixels, for a dimension.
   *
   * @param event
   * @returns
   */
  onPointerMoveForCorner(event: FederatedPointerEvent): void {
    if (!this.currentRectangle) {
      return;
    }

    if (this.interactorId == TOP_LEFT) {
      // When moving TOP_LEFT, the bottom right corner should remain static.
      // We change the height, width, x and y.
      if (
        event.globalX < this.startX + this.startWidth &&
        event.globalY < this.startY + this.startHeight
      ) {
        this.currentRectangle.updateObject({
          x: event.globalX,
          y: event.globalY,
          // Need to consider the canvas.
          height: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(this.startY + this.startHeight - event.globalY)
          ),
          width: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(this.startX + this.startWidth - event.globalX)
          ),
        });
      }
      return;
    }

    if (this.interactorId == TOP_RIGHT) {
      // When moving TOP_RIGHT, the bottom left corner should remain static.
      // We change the height, width, x y.
      if (event.globalX > this.startX && event.globalY < this.startY + this.startHeight) {
        this.currentRectangle.updateObject({
          y: event.globalY,
          // Need to consider the canvas.
          height: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(this.startHeight - (event.globalY - this.startY))
          ),
          width: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(event.globalX - this.startX)
          ),
        });
      }
      return;
    }

    if (this.interactorId == BOTTOM_LEFT) {
      // When moving BOTTOM_LEFT, the top right corner should remain static.
      if (event.globalX < this.startX + this.startWidth && event.globalY > this.startY) {
        this.currentRectangle.updateObject({
          x: event.globalX,
          // Need to consider the canvas.
          height: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(event.globalY - this.startY)
          ),
          width: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(this.startWidth - (event.globalX - this.startX))
          ),
        });
      }
      return;
    }

    if (this.interactorId == BOTTOM_RIGHT) {
      // When moving BOTTOM_RIGHT, the top left corner should remain static.
      if (event.globalX > this.startX && event.globalY > this.startY) {
        this.currentRectangle.updateObject({
          height: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(event.globalY - this.startY)
          ),
          width: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(event.globalX - this.startX)
          ),
        });
      }
      return;
    }
  }

  onPointerMoveForSide(event: FederatedPointerEvent): void {
    if (!this.currentRectangle) {
      return;
    }

    if (this.interactorId == TOP) {
      // When moving TOP, the bottom y axis should remain static.
      // This means that the height == [the starting Y + height] - y
      if (event.globalY < this.startY + this.startHeight) {
        this.currentRectangle.updateObject({
          y: event.globalY,
          // Need to consider the canvas.
          height: Math.max(
            1,
            // y gets larger as it goes down.
            Math.abs(this.startY + this.startHeight - event.globalY)
          ),
        });
      }
      return;
    }

    if (this.interactorId == LEFT) {
      // When moving LEFT, the right x should remain static.
      if (event.globalX < this.startX + this.startWidth) {
        this.currentRectangle.updateObject({
          x: event.globalX,
          width: Math.max(
            1,
            // x gets larger as it goes to the right
            Math.abs(this.startX + this.startWidth - event.globalX)
          ),
        });
      }
      return;
    }

    if (this.interactorId == RIGHT) {
      // When moving RIGHT, the left x axis should remain static.
      if (event.globalX > this.startX) {
        this.currentRectangle.updateObject({
          width: Math.max(
            1,
            // y gets larger as it goes down
            Math.abs(event.globalX - this.startX)
          ),
        });
      }
      return;
    }

    if (this.interactorId == BOTTOM) {
      // When moving TOP, the top y axis should remain static.
      if (event.globalY > this.startY) {
        this.currentRectangle.updateObject({
          height: Math.max(
            1,
            // y gets larger as it goes down
            Math.abs(this.startY - event.globalY)
          ),
        });
      }
      return;
    }
  }
}
