import { ToasterToast, useToast } from "@/components/ui/use-toast";
import { Engine } from "@/nexus/engine/engine";
import { useEffect, useState } from "react";

export interface CanvasToastsProps {
  engine: Engine;
}

// https://ui.shadcn.com/docs/components/toast
// To display multiple toasts at the same time, you can update the TOAST_LIMIT in use-toast.tsx.
const CanvasToasts = ({ engine }: CanvasToastsProps): JSX.Element => {
  const [toasts, setToasts] = useState<ToasterToast[]>([]);
  engine.browserConnector.initToasts([toasts, setToasts]);

  // TODO: Better understand useEffect.
  // Dispatching a Toast, if toasts change -> send off a toast.
  const { toast } = useToast();
  useEffect(() => {
    while (toasts.length > 0) {
      const currentToast = toasts.pop();
      if (currentToast) {
        toast(currentToast);
        setToasts(toasts);
      }
    }
  }, [toasts]);

  return <div hidden={true}>{toasts.length}</div>;
};

export default CanvasToasts;
