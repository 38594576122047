import { FederatedPointerEvent } from "pixi.js/lib/events/FederatedPointerEvent";
import { Engine, EngineState } from "../../engine";
import { BaseObject } from "../../objects/BaseObject";

export class ObjectMovingEngine {
  engine: Engine;

  constructor(engine: Engine) {
    this.engine = engine;
  }

  // TODO: When we're moving to active state

  // TODO: Have a better way of doing this. Maybe we instantiate this with the Patch... or even
  // just have this defined on the patch itself.
  currentObject: BaseObject | undefined;
  startX = 0;
  startY = 0;

  onPointerDown(event: FederatedPointerEvent): void {
    if (
      this.currentObject &&
      (this.engine.currentState == EngineState.ObjectSelected ||
        this.engine.currentState == EngineState.Browsing)
    ) {
      // We're starting to scroll - save the current coordinates to calculate the offset.
      this.startX = event.globalX;
      this.startY = event.globalY;

      this.currentObject.debug();

      // Turn on scrolling.
      this.engine.setEngineState(EngineState.MovingObjectActive);

      // This means we're in scroll mode -> so we move up the stage.
    }
  }

  // This should be fired off from the base stage container.
  onPointerMove(event: FederatedPointerEvent): void {
    if (this.engine.currentState == EngineState.MovingObjectActive) {
      // Calculate offset, and update. See ScrollingEngine.ts for similar logic.

      // Why is event null here?
      const lastOffsetX = event.globalX - this.startX;
      const lastOffsetY = event.globalY - this.startY;

      if (this.currentObject) {
        this.engine.canvasStateManager?.movePatch(this.currentObject, lastOffsetX, lastOffsetY);
      }
    }
  }

  // End moving! Reset the canvas back to the original state.
  onPointerUp(): void {
    // Save the change in the object.
    if (this.engine.currentState == EngineState.MovingObjectActive) {
      if (this.currentObject && this.engine.canvasStateManager) {
        this.currentObject.config.x =
          this.currentObject.container.x - this.engine.canvasStateManager.offsetX;
        this.currentObject.config.y =
          this.currentObject.container.y - this.engine.canvasStateManager.offsetY;
        // this.currentPatch.debug();
        // This probably doesn't update the underlying store...
        // Trigger a save.

        // Is this needed? Hmm...
        this.engine.canvasStateManager?.setBasePatch(this.currentObject);
        this.engine.engineStateManager?.save();

        // Reset.
        this.startX = 0;
        this.startY = 0;
        this.currentObject = undefined;
      }
      this.engine.setEngineState(EngineState.ObjectSelected);
    }
  }
}
