import { createRef, useRef } from "react";
import CanvasModeBar from "./canvas/canvas-mode-bar";
import { Application, ICanvas } from "pixi.js";
import { useEffect } from "react";
import * as PIXI from "pixi.js";
import { Engine } from "../../engine/engine";
import { BrowserConnector } from "../../engine/connectors/BrowserConnector";
import CanvasContextMenu from "./canvas/canvas-context-menu";
import { BackendConnector } from "../../engine/connectors/BackendConnector";
import CanvasToasts from "./canvas/canvas-toasts";
import CanvasEditor from "./canvas/canvas-editor";
import { cn } from "@/lib/utils";

// https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
export const Canvas = (): JSX.Element => {
  // Initialize the Engine that is responsible for all of the editing / display logic.
  // NOTE: For now we initialize the browser/backend connector here, but these may be initialized
  // up the stack as when we need (1) more browser hooks and (2) establish a ws connection w/ the
  // backend when it is built and isn't just a stub.
  const browserConnector = new BrowserConnector();
  const backendConnector = new BackendConnector();
  const engine = new Engine(backendConnector, browserConnector);

  // Initialize the <canvas> and the pixi.js application that powers it.
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasWrapperRef = createRef<HTMLElement>();

  const app = new PIXI.Application();
  let initiated = false;
  useEffect(() => {
    async function initApp(app: Application) {
      // This is weird. Fonts don't load with the Inter-*.woff2 and had to
      // rename it for it to work.
      //
      // Might need to loop through things to load the texts
      await PIXI.Assets.load("./fonts/inter.woff2");

      // Then initialize the app.
      await app.init({
        // background: "#211f18",
        view: canvasRef.current as ICanvas,
        eventFeatures: {
          move: true,
          click: true,
          wheel: true,
        },
        resizeTo: canvasWrapperRef.current as HTMLElement,
        // This does look nice, but let's see.
        // See https://github.com/pixijs/graphics-smooth/issues/44.
        // https://github.com/pixijs/pixijs/pull/10702
        antialias: true,
        autoDensity: true,
        resolution: 2,
      });
    }
    // TODO: For some reason this is read twice.
    console.log("Canvas is initializing.");
    if (!initiated) {
      console.log("Canvas actually initializing.");
      initApp(app);
      engine.init(app);
      initiated = true;
    }
  }, [app]);

  // This is nice:
  // https://stackoverflow.com/questions/1575141/how-to-make-a-div-100-height-of-the-browser-window
  // Context menu -> But how to... have the state propogate?
  // Wrapping this in a context menu is interesting... wonder how we can get the state to propogate.
  return (
    // Maybe it makes sense to have a div that goes _over_ the canvas.
    <div
      id="canvas-container"
      style={{
        display: "grid",
        gridTemplateColumns: "250px 1fr 250px",
        gridTemplateRows: "56px",
        columnGap: "10px",
        height: "100vh",
      }}
    >
      <div className={cn("space-y-1.5 p-2 col-start-1	col-end-3")}>
        <CanvasModeBar engine={engine} />
      </div>

      <div
        ref={canvasWrapperRef as React.RefObject<HTMLDivElement>}
        id="canvas"
        style={{
          zIndex: 1,
          position: "relative",
          gridColumnStart: 1,
          gridColumnEnd: 3,
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <CanvasContextMenu engine={engine} />
        <canvas ref={canvasRef} />
      </div>

      <div style={{ gridColumnStart: 3 }}>
        <CanvasEditor engine={engine} />
      </div>
      <CanvasToasts engine={engine} />
    </div>
  );
};
