import { z } from "zod";

/**
 * WorkingSpace contains all of the information needed to power the UI.
 *
 * This doesn't include helper classes & functions that hold object state and the like, e.g.
 * maps and usch.
 */
const QuiltsData = z.record(z.string(), z.any());
export type QuiltsData = z.infer<typeof QuiltsData>;

// All that is needed to fetch the data.
export const DataConfigObject = z.object({
  // Each data comes from a source (e.g. Datadog app, etc)
  dataSource: z.string(),
  // Key for the data, e.g. what the key is called in Datadog.
  dataKey: z.string(),
});
export type DataConfig = z.infer<typeof DataConfigObject>;

export const DataThresholdObject = z.object({
  threshold: z.number(),
  color: z.string().optional(),
});
export type DataThreshold = z.infer<typeof DataThresholdObject>;

// All that is needed to wire up a configuration.
export const DataIntegrationObject = z.object({
  dataConfig: DataConfigObject,
  // Need to know what threshold(s) and how we should change the object (transform).
  thresholdConfig: DataThresholdObject.optional(),
});
export type DataIntegration = z.infer<typeof DataIntegrationObject>;

const ObjectConfig = z.object({
  id: z.string(),
  // Where on the canvas this is located.
  //   - The actual container x and y can be different, as we can scroll around.
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
  color: z.string(),
  name: z.string(),
  // Data Elements
  dataIntegration: DataIntegrationObject.optional(),
});

const CanvasConfig = z.object({
  basePatches: z.array(ObjectConfig),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const WorkingSpace = z.array(CanvasConfig);

export type ObjectConfig = z.infer<typeof ObjectConfig>;
export type CanvasConfig = z.infer<typeof CanvasConfig>;
export type WorkingSpace = z.infer<typeof WorkingSpace>;
