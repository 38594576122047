import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { cn } from "@/lib/utils";
import { Engine, DEFAULT_ENGINE_STATE, EngineState } from "@/nexus/engine/engine";
import { HomeIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Link } from "react-router-dom";

export interface CanvasModeBarProps {
  engine: Engine;
}

const ICON_SIZE = 12;

// NOTE: If we wanted real type safety - we could fork the primitives for ToggleGroup and pass through Enums.
const CanvasModeBar = ({ engine }: CanvasModeBarProps): JSX.Element => {
  const [currentCanvasIndex, setCurrentCanvasIndex] = useState(1);
  const [workspaceTableState, setWorkspaceTableState] = useState(false);
  engine.browserConnector.initializeWorkspace(
    [currentCanvasIndex, setCurrentCanvasIndex],
    [workspaceTableState, setWorkspaceTableState]
  );

  // Dynamic Table
  const tableContent = (
    <MenubarSubContent>
      {engine.engineStateManager?.workingSpace.map((canvas, key) => {
        return (
          <MenubarItem
            onClick={() => {
              engine.engineStateManager?.changeCanvas(key);
            }}
            key={key}
            style={
              key == engine.engineStateManager?.currentCanvasIndex
                ? { backgroundColor: "grey", cursor: "pointer" }
                : { cursor: "pointer" }
            }
          >
            {canvas.name}
          </MenubarItem>
        );
      })}
    </MenubarSubContent>
  );

  const [cursorMode, setCursorMode] = useState(
    engine.currentState.toString() || DEFAULT_ENGINE_STATE.toString()
  );
  const cursorModeChange = (value: string) => {
    setCursorMode(value);
    engine.setEngineState(value);
  };

  const [title, setTitle] = useState("Untitled");
  const titleChange = (value: string) => {
    setTitle(value);
    engine.setTitle(value);
  };
  engine.browserConnector.initializeCanvasModeBar([title, setTitle]);

  // On-click -> do something.
  // The svg for the MenubarTrigger depends on state -> depending on that
  // particular action.
  //
  // The highlight needs to also be dependent on State.

  const generateMenuItem = (currentCursorMode: EngineState, d: string, d2?: string) => {
    return (
      <MenubarMenu>
        <MenubarTrigger
          className={cursorMode == currentCursorMode.toString() ? cn("bg-accent") : ""}
          onClick={() => {
            cursorModeChange(currentCursorMode.toString());
          }}
        >
          <svg
            viewBox="0 0 24 24"
            width={ICON_SIZE}
            height={ICON_SIZE}
            aria-hidden="true"
            style={{ fill: "white" }}
          >
            <path d={d} />
            {d2 ? <path d={d2} /> : null}
          </svg>
        </MenubarTrigger>
      </MenubarMenu>
    );
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "40px 300px 300px", gap: "10px" }}>
      <Button variant="outline" size="icon" asChild>
        <Link to="/">
          <HomeIcon />
        </Link>
      </Button>
      <Menubar>
        <MenubarMenu>
          <MenubarTrigger>
            <svg
              viewBox="0 0 24 24"
              width={ICON_SIZE}
              height={ICON_SIZE}
              aria-hidden="true"
              style={{ fill: "white" }}
            >
              <path d="m23.493,11.017c-.487-.654-1.234-1.03-2.05-1.03h-.443v-1.987c0-2.757-2.243-5-5-5h-5.056c-.154,0-.31-.037-.447-.105l-3.155-1.578c-.414-.207-.878-.316-1.342-.316h-2C1.794,1,0,2.794,0,5v13c0,2.757,2.243,5,5,5h12.558c2.226,0,4.15-1.432,4.802-3.607l1.532-6.116c.234-.782.089-1.605-.398-2.26ZM2,18V5c0-1.103.897-2,2-2h2c.154,0,.31.037.447.105l3.155,1.578c.414.207.878.316,1.342.316h5.056c1.654,0,3,1.346,3,3v1.987h-10.385c-1.7,0-3.218,1.079-3.789,2.72l-2.19,7.138c-.398-.509-.636-1.15-.636-1.845Zm19.964-5.253l-1.532,6.115c-.384,1.279-1.539,2.138-2.874,2.138H5c-.208,0-.411-.021-.607-.062l2.334-7.609c.279-.803,1.039-1.342,1.889-1.342h12.828c.242,0,.383.14.445.224.062.084.156.259.075.536Z" />
            </svg>
          </MenubarTrigger>
          <MenubarContent>
            <MenubarItem onClick={() => engine.engineStateManager?.createCanvas()}>
              New Quilt <MenubarShortcut>⌘T</MenubarShortcut>
            </MenubarItem>
            <MenubarSeparator />
            <MenubarSub>
              <MenubarSubTrigger>Open Quilt</MenubarSubTrigger>
              {tableContent}
            </MenubarSub>
          </MenubarContent>
        </MenubarMenu>
        {generateMenuItem(
          EngineState.Browsing,
          "m18.624,9.674L7.947.566C7.225-.041,6.245-.17,5.39.229c-.857.4-1.39,1.237-1.39,2.185v14.132c0,1.026.618,1.925,1.575,2.291.954.365,2.01.108,2.686-.647l1.754-1.891,3.263,6.344c.44.855,1.325,1.353,2.236,1.353.364,0,.732-.079,1.078-.247.61-.296,1.065-.813,1.28-1.458.215-.644.163-1.331-.147-1.935l-3.269-6.356h2.716c1.027-.05,1.869-.688,2.198-1.665.329-.979.044-1.998-.745-2.662Zm-.203,2.342c-.152.453-.574.95-1.275.984h-3.511c-.174,0-.336.09-.427.239-.091.148-.098.334-.018.489l3.643,7.084c.187.362.218.775.089,1.161s-.402.697-.769.875c-.726.354-1.618.055-1.987-.664l-3.589-6.979c-.075-.145-.215-.245-.377-.267-.022-.003-.239-.036-.435.155l-2.245,2.42c-.489.547-1.142.558-1.589.387-.45-.172-.932-.619-.932-1.356V2.413c0-.563.304-1.041.812-1.278.506-.235,1.063-.162,1.488.194l10.674,9.106s.002.002.003.002c.56.47.596,1.124.442,1.579Z"
        )}
        {generateMenuItem(
          EngineState.Drawing,
          "M22.987,5.452c-.028-.177-.312-1.767-1.464-2.928-1.157-1.132-2.753-1.412-2.931-1.44-.237-.039-.479,.011-.682,.137-.071,.044-1.114,.697-3.173,2.438,1.059,.374,2.428,1.023,3.538,2.109,1.114,1.09,1.78,2.431,2.162,3.471,1.72-2.01,2.367-3.028,2.41-3.098,.128-.205,.178-.45,.14-.689Z",
          "M12.95,5.223c-1.073,.968-2.322,2.144-3.752,3.564C3.135,14.807,1.545,17.214,1.48,17.313c-.091,.14-.146,.301-.159,.467l-.319,4.071c-.022,.292,.083,.578,.29,.785,.188,.188,.443,.293,.708,.293,.025,0,.051,0,.077-.003l4.101-.316c.165-.013,.324-.066,.463-.155,.1-.064,2.523-1.643,8.585-7.662,1.462-1.452,2.668-2.716,3.655-3.797-.151-.649-.678-2.501-2.005-3.798-1.346-1.317-3.283-1.833-3.927-1.975Z"
        )}
        {generateMenuItem(
          EngineState.ScrollingPending,
          "m22.119,3.595c-.56-.477-1.301-.679-2.035-.562-.405.066-.771.244-1.084.492v-.026c0-1.378-1.122-2.5-2.5-2.5-.64,0-1.218.249-1.66.645-.351-.957-1.263-1.645-2.34-1.645-1.378,0-2.5,1.122-2.5,2.5v.026c-.313-.248-.679-.426-1.084-.492-.732-.116-1.475.085-2.035.562-.56.476-.881,1.17-.881,1.905v10.601l-1.796-1.428c-1.006-.94-2.588-.888-3.529.119s-.889,2.593.111,3.53l3.354,3.234c2.304,2.221,5.334,3.444,8.534,3.444h2.352c4.397,0,7.974-3.577,7.974-7.974V5.5c0-.734-.321-1.429-.881-1.905Zm-.119,12.431c0,3.846-3.128,6.974-6.974,6.974h-2.352c-2.939,0-5.724-1.124-7.84-3.164l-3.359-3.239c-.604-.566-.635-1.519-.07-2.123.273-.292.644-.46,1.043-.474.409-.007.781.129,1.103.428l2.637,2.1c.151.12.356.144.529.059.173-.083.283-.258.283-.45V4.5c0-.44.193-.857.529-1.143.341-.29.778-.408,1.227-.336.709.115,1.245.797,1.245,1.586v5.893c0,.276.224.5.5.5s.5-.224.5-.5V2.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v8c0,.276.224.5.5.5s.5-.224.5-.5V3.5c0-.827.673-1.5,1.5-1.5s1.5.673,1.5,1.5v7c0,.276.224.5.5.5s.5-.224.5-.5v-4.893c0-.789.535-1.471,1.244-1.586.451-.074.886.046,1.227.336.336.286.529.703.529,1.143v10.526Z"
        )}
      </Menubar>
      <Input
        type="string"
        onChange={(event) => {
          titleChange(event.target.value);
        }}
        placeholder="Title"
        value={title}
      />
    </div>
  );
};

export default CanvasModeBar;
