import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { SignedIn, SignedOut, SignInButton, SignOutButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { LockClosedIcon, RocketIcon } from "@radix-ui/react-icons";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
export default function IndexPage(): React.ReactElement {
  return (
    <div className={cn("grid grid-cols-3 grid-rows-3")}>
      <div className={cn("flex-col col-start-2 col-end-2 row-start-2")}>
        <Card>
          <CardHeader className="flex flex-col justify-center items-center">
            <CardTitle>Welcome to Quilts! 👋</CardTitle>
            <CardDescription>Your (soon to be) new favorite data tool.</CardDescription>
          </CardHeader>
          <CardContent className="flex flex-col justify-center items-center">
            <SignedOut>
              <SignInButton mode="modal">
                <Button className="w-48 p-4">
                  <LockClosedIcon className="mr-2 h-4 w-4" /> Secure Sign In{" "}
                </Button>
              </SignInButton>
            </SignedOut>
            <SignedIn>
              <Button className="w-48 m-1" asChild>
                <Link to="/dashboard">
                  <RocketIcon className="mr-2 h-4 w-4" /> Dashboard
                </Link>
              </Button>
              <SignOutButton>
                <Button variant="destructive" className="w-48 m-1">
                  Sign Out
                </Button>
              </SignOutButton>
            </SignedIn>
          </CardContent>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </div>
  );
}
