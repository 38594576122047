import { Engine } from "../engine";
import { BaseObject } from "../objects/BaseObject";
import { ObjectConfig, CanvasConfig, WorkingSpace } from "../types/WorkingSpaceTypes";

// Any state in the Engine should be saved _somewhere_ and then that updates appropriately
// with the backend connector.
//
// Need a single place where all of the state needed to draw the Canvas/Engine is in a single place.
// This backend -> is what we stringify and send to the server appropriately.
//
// Consider the split of responsibilities betweent his and CanvasStateManager.

/**
 * Handles the overall engine state: saving, initializing, syncing with the session / backend.
 */
export class EngineStateManager {
  engine: Engine;

  currentCanvasIndex = 0;
  workingSpace: WorkingSpace = [
    {
      basePatches: [],
      id: "Hello",
      name: "TODO: name",
      timestamp: Date.now(),
    },
  ];

  constructor(engine: Engine) {
    this.engine = engine;
  }

  createCanvas(): void {
    this.currentCanvasIndex += 1;
    this.workingSpace.push({
      basePatches: [],
      id: "Hello + " + this.currentCanvasIndex,
      name: "TODO: name",
      timestamp: Date.now(),
    });

    // This then needs to propogate to the canvas.
    this.engine.browserConnector.setCurrentCanvasIndex(this.currentCanvasIndex);
  }

  changeCanvas(index: number): void {
    this.currentCanvasIndex = index;
    this.engine.canvasStateManager?.updateToFreshCanvas();
    this.engine.browserConnector.setCurrentCanvasIndex(this.currentCanvasIndex);

    // TODO: we generally should have an initiate the canvas function.
    // update the title
    this.engine.browserConnector.setTitle(this.currentCanvas().name);
  }

  init(): void {
    // TODO: Update the workingSpace table?
    const workingSpaceFromBackend = this.engine.backendConnector.fetchWorkingSpace();
    if (workingSpaceFromBackend.length > 0) {
      this.workingSpace = workingSpaceFromBackend;
    }

    this.engine.canvasStateManager?.updateToFreshCanvas();
    this.engine.browserConnector.setCurrentCanvasIndex(this.currentCanvasIndex);
    this.engine.browserConnector.setTitle(this.currentCanvas().name);
  }

  currentCanvas(): CanvasConfig {
    // TODO: validation on the currentCanvasIndex.
    return this.workingSpace[this.currentCanvasIndex];
  }

  deleteCanvas(): void {
    // Q: Does this make sense to pass through this vs. just the engine?
    // A: Yeah - we should treat the connectors like RPC boundaries.
    this.engine.backendConnector.deleteCanvas(this.workingSpace, this.currentCanvasIndex);
  }

  /**
   * This is the big piece, the engine currentState and such -> we should probably
   * move into this.
   *
   * TODO: Have this have a buffer.
   *   - We will want a buffer of save events as well, for historical reasons.
   */
  save(): void {
    const basePatches: ObjectConfig[] = [];
    this.engine.canvasStateManager?.baseObjectMap.forEach((basePatch: BaseObject, _key: string) => {
      basePatches.push(basePatch.getConfig());
    });
    // Update the basePatches in the state, depending on what's in the canvas.
    // TODO: This should be a serde.
    this.workingSpace[this.currentCanvasIndex].basePatches = basePatches;
    this.engine.backendConnector.saveCanvas(this.workingSpace);
    this.engine.browserConnector.addToast({
      id: "",
      title: "Saved!",
      description: "Save was successful.",
    });
  }
}
