import { ToasterToast } from "@/components/ui/use-toast";
import {
  CanvasContextMenuConfig,
  CanvasContextMenuState,
} from "@/nexus/pages/quilt-editor/canvas/canvas-context-menu-configs";
import { ObjectConfig } from "../types/WorkingSpaceTypes";

/**
 * Handles connecting the Canvas to the React app
 */
export class BrowserConnector {
  // NOTE: We may want to make these private as well.

  // Maybe we want subclasses here? Or modules put here? e.g. browserConnector.workspace.update() and so forth.
  editorIsOpen: boolean | undefined;
  currentCanvasIndex: number | undefined;
  editorState: Map<string, boolean> | undefined;
  canvasContextMenuIsOpen: boolean | undefined;
  canvasTitle: string | undefined;
  #toasts: ToasterToast[] | undefined;

  // CanvasEditor
  #setObjectConfig: React.Dispatch<React.SetStateAction<ObjectConfig | undefined>> | undefined;

  initializeCanvasEditor(
    setObjectConfig: React.Dispatch<React.SetStateAction<ObjectConfig | undefined>>
  ): void {
    this.#setObjectConfig = setObjectConfig;
  }

  setObjectConfig(config: ObjectConfig): void {
    if (this.#setObjectConfig) {
      const newObject = { ...config };
      this.#setObjectConfig(newObject);
    }
  }

  resetObjectConfig(): void {
    if (this.#setObjectConfig) {
      this.#setObjectConfig(undefined);
    }
  }

  // Need to reset all state in the editor.
  // Do we really need to register every single one?

  // This is for force refreshing.
  #workspaceTableState: boolean | undefined;
  #setWorkspaceTableState: React.Dispatch<React.SetStateAction<boolean>> | undefined;

  // NOTE: We should isolate all of the State change calls into this connector so we have better control.
  #setEditorIsOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  #setCurrentCanvasIndex: React.Dispatch<React.SetStateAction<number>> | undefined;
  #setEditorStateHook: React.Dispatch<React.SetStateAction<Map<string, boolean>>> | undefined;
  #setCanvasContextMenuState:
    | React.Dispatch<React.SetStateAction<CanvasContextMenuState>>
    | undefined;
  #setCanvasTitle: React.Dispatch<React.SetStateAction<string>> | undefined;
  #setToasts: React.Dispatch<React.SetStateAction<ToasterToast[]>> | undefined;

  initToasts(toasts: [ToasterToast[], React.Dispatch<React.SetStateAction<ToasterToast[]>>]): void {
    [this.#toasts, this.#setToasts] = toasts;
  }

  addToast(toast: ToasterToast): void {
    // TODO: If we need to do multiple toasts, see how we would want to buffer them.
    if (this.#setToasts) {
      this.#setToasts([toast]);
    }
  }

  initializeWorkspace(
    canvasTable: [number, React.Dispatch<React.SetStateAction<number>>],
    workspaceTableState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  ): void {
    [this.currentCanvasIndex, this.#setCurrentCanvasIndex] = canvasTable;
    [this.#workspaceTableState, this.#setWorkspaceTableState] = workspaceTableState;
  }

  refreshWorkspaceTable(): void {
    if (this.#setWorkspaceTableState) {
      this.#setWorkspaceTableState(!this.#workspaceTableState);
    }
  }

  initializeEditor(
    editorConfigUseState: [
      Map<string, boolean>,
      React.Dispatch<React.SetStateAction<Map<string, boolean>>>,
    ],
    editorIsOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
  ): void {
    [this.editorState, this.#setEditorStateHook] = editorConfigUseState;
    [this.editorIsOpen, this.#setEditorIsOpen] = editorIsOpenState;
  }

  initializeCanvasContextMenu(
    setCanvasContextMenuState: React.Dispatch<React.SetStateAction<CanvasContextMenuState>>
  ): void {
    this.#setCanvasContextMenuState = setCanvasContextMenuState;
  }

  initializeCanvasModeBar(
    canvasTitle: [string, React.Dispatch<React.SetStateAction<string>>]
  ): void {
    [this.canvasTitle, this.#setCanvasTitle] = canvasTitle;
  }

  setTitle(title: string): void {
    if (this.#setCanvasTitle) {
      this.#setCanvasTitle(title);
    }
  }

  setEditorState(key: string, value: boolean): void {
    // this.editorState.set(key, value);
    if (this.#setEditorStateHook) {
      const newMap = new Map();
      newMap.set(key, value);
      this.#setEditorStateHook(newMap);
    }
  }

  setCurrentCanvasIndex(value: number): void {
    if (this.#setCurrentCanvasIndex) {
      this.#setCurrentCanvasIndex(value);
    }
  }

  openEditor(): void {
    if (this.#setEditorIsOpen) {
      this.#setEditorIsOpen(true);
    }
  }

  setCanvasContextMenuState(
    x: number,
    y: number,
    show: boolean,
    canvasContextType?: CanvasContextMenuConfig
  ): void {
    if (this.#setCanvasContextMenuState) {
      this.#setCanvasContextMenuState({ x: x, y: y, show: show, menuConfig: canvasContextType });
    }
  }
}
